<template>
  <div>
    <v-row dense>
      <v-col cols="6" md="2" sm="3">
        <DateRangeFilter
          :label="$t('labels.from_to')"
          :placeholder="$t('labels.from_to')"
          :default-value="filters.data_date"
          name="data_date"
          sort-name="data_date"
          @onFilter="onFilterChange"
          @onSort="onSortChange"
        />
      </v-col>
      <v-col cols="6" md="2" sm="3">
        <SelectFilter
          :label="$t('labels.forecast_out_of_stock')"
          :placeholder="$t('labels.forecast_out_of_stock')"
          name="forecast_out_of_stock"
          sort-name="forecast_out_of_stock"
          :options="forecastOutOfStockOptions"
          :default-value="filters.forecast_out_of_stock"
          @onFilter="onFilterChange"
          @onSort="onSortChange"
        />
      </v-col>
      <v-col cols="6" md="2" sm="3">
        <InputFilter
          :label="$t('labels.most_recent_period')"
          :placeholder="$t('labels.most_recent_period')"
          name="most_recent_period"
          sort-name="most_recent_period"
          :default-value="filters.most_recent_period"
          @onFilter="onFilterChange"
          @onSort="onSortChange"
        />
      </v-col>
      <v-col cols="6" md="2" sm="3">
        <SelectCustomer
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          name="id_customer"
          sort-name="id_customer"
          :default-value="filters.id_customer"
          @onFilter="onFilterChange"
          @onSort="onSortChange"
        />
      </v-col>
      <v-col cols="6" md="4" sm="12">
        <div class="text-right" v-if="filters.id_customer">
          <v-btn
            :color="
              checkModule(['warehouse_goods_report_summary'])
                ? 'info'
                : 'default'
            "
            @click="downloadExcelSummary"
            class="mr-2"
          >
            <v-icon>mdi-email-fast</v-icon> {{ $t("labels.email_goods_value") }}
          </v-btn>
          <v-btn color="success" @click="showChartDialog({})"
            >Biểu đồ tổng</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top mt-5"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th>
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th>
              <SelectPos
                :label="$t('labels.pos')"
                :placeholder="$t('labels.pos')"
                name="id_pos"
                sort-name="pos_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :auto-select="false"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.category')"
                :placeholder="$t('labels.category')"
                name="category_name"
                sort-name="category_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="description"
                sort-name="description"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="size"
                sort-name="size"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilterFromTo
                :label="$t('labels.total_in_order')"
                :placeholder="$t('labels.total_in_order')"
                name="total_in_order"
                sort-name="total_in_order"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilterFromTo
                :label="$t('labels.total_handover')"
                :placeholder="$t('labels.total_handover')"
                name="total_handover"
                sort-name="total_handover"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">Tỷ lệ</th>
            <th class="text-center">
              <InputFilterFromTo
                :label="$t('labels.available')"
                :placeholder="$t('labels.available')"
                name="available"
                sort-name="available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">Dự báo hết hàng</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :key="`${item.id}_${item.id_warehouse}_${item.id_pos}`"
            class="text-center"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ item.pos_code }}</td>
            <td>
              <span
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showDetailDialog(item)"
              >
                {{ item.customer_goods_barcode }}
              </span>
              <v-icon
                color="warning"
                class="ml-1 cursor-pointer"
                small
                @click="showChartDialog(item)"
                >mdi-chart-line</v-icon
              >
            </td>
            <td>{{ item.sku }}</td>
            <td>{{ item.category_name }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.size }}</td>
            <td>{{ formatNumber(item.total_in_order) }}</td>
            <td>{{ formatNumber(item.total_handover) }}</td>
            <td>{{ item.ratio }}</td>
            <td>{{ formatNumber(item.available) }}</td>
            <td>{{ item.forecast }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <div class="d-flex align-center">
          <v-btn color="info" @click="downloadExcel" class="mr-2">
            <v-icon>mdi-download</v-icon>
            Excel
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="4"> </v-col>
    </v-row>

    <v-dialog v-model="detailDialog" max-width="1024px">
      <ReportDetail
        v-if="detailDialog"
        :item="detailItem"
        @cancel="hideDetailDialog"
      />
    </v-dialog>

    <v-dialog v-model="chartDialog" max-width="1024px">
      <ReportChart
        v-if="chartDialog"
        :item="detailItem"
        :dates="filters.data_date"
        :id-customer="filters.id_customer"
        @cancel="hideChartDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
const DEFAULT_DATES = [
  moment().subtract("30", "days").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD"),
];

export default {
  name: "ReportList",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectPos: () => import("@/components/table/SelectPos"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    ReportDetail: () => import("@/components/goods/ReportDetail"),
    ReportChart: () => import("@/components/goods/ReportChart"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    sum: {},
    page: 1,
    total: 0,
    totalPage: 1,
    viewOption: "chart",
    chartColumns: [],
    filters: {
      sort_by: "available",
      sort_type: "desc",
    },
    detailItem: {},
    detailDialog: false,
    chartDialog: false,
  }),
  computed: {
    periodOptions() {
      return [
        {
          text: "01 ngày",
          value: 1,
        },
        {
          text: "07 ngày",
          value: 7,
        },
        {
          text: "10 ngày",
          value: 10,
        },
        {
          text: "30 ngày",
          value: 30,
        },
      ];
    },
    forecastOutOfStockOptions() {
      return [
        {
          text: "Theo tạo đơn",
          value: 1,
        },
        {
          text: "Theo xuất kho",
          value: 2,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.items = [];
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    this.filters = {
      ...this.filters,
      data_date: [...DEFAULT_DATES],
      forecast_out_of_stock: 2,
      most_recent_period: 30,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    showDetailDialog(item) {
      this.detailItem = { ...item };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.detailItem = {};
      this.detailDialog = false;
    },
    showChartDialog(item) {
      this.detailItem = { ...item };
      this.chartDialog = true;
    },
    hideChartDialog() {
      this.detailItem = {};
      this.chartDialog = false;
    },
    getList: debounce(function () {
      httpClient
        .post("/goods/v1/report-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.sum = { ...data.sum };
          this.items = [...data.rows].map((item) => {
            return {
              ...item,
              total_forecast_handover: +item.total_forecast_handover,
              total_forecast_order: +item.total_forecast_order,
              total_handover: +item.total_handover,
              total_in_order: +item.total_in_order,
            };
          });
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "toc-do-voi-hang.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods/v1/report-list-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
        this.$vToastify.success(
          "Chờ chút nhé. Báo cáo sẽ được gửi về Email của bạn!"
        );
      } catch (e) {
        this.isLoading = false;
      }
    },

    async downloadExcelSummary() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods/v1/report-export-summary", {
          ...this.filters,
          page: this.page,
        });
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>
